<template>
  <div class="dynamic-select" :class="{ 'dynamic-select--border': hasBorder }">
    <select
      ref="selectElement"
      :class="`s-form-control ${selectClass}`"
      v-model="selected"
      :disabled="disabled"
    >
      <option v-if="placeholder" :value="null">{{ placeholder }}</option>
      <option :value="value" :key="value" v-for="{ text, value } in options">
        {{ text }}
      </option>
    </select>
    <div class="dynamic-select__caret">
      <svg
        v-if="hasBorder"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
      >
        <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path
          d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"
        />
      </svg>
      <svg
        v-else
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.38672 13.4707C9.62402 13.4707 9.85254 13.374 10.0107 13.1982L16.418 6.6416C16.5674 6.49219 16.6553 6.29004 16.6553 6.06152C16.6553 5.5957 16.2949 5.23535 15.8291 5.23535C15.6094 5.23535 15.3984 5.32324 15.2402 5.47266L9.39551 11.458L3.54199 5.47266C3.38379 5.32324 3.18164 5.23535 2.94434 5.23535C2.47852 5.23535 2.11816 5.5957 2.11816 6.06152C2.11816 6.29004 2.20605 6.49219 2.36426 6.65039L8.77148 13.1982C8.94727 13.374 9.14941 13.4707 9.38672 13.4707Z"
          :fill="themeColor"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "success",
    },
    value: [String, Number],
    options: Array,
    placeholder: String,
    themeColor: {
      type: String,
      default: "var(--liff-primary_text_color)",
    },
    selectClass: String,
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    handleFocus() {
      // 展開選項列表
      this.$nextTick(() => {
        const selectElement = this.$refs.selectElement;
        selectElement.focus();
      });
    },
  },
};
</script>

<style lang="scss">
.dynamic-select {
  position: relative;
  font-family: "PingFang TC";
  font-style: normal;
  font-weight: 600;
  width: 100%;

  select {
    padding: 0.8rem;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    font-size: 16px;
    border: dotted 1px var(--liff-button-color) !important;
    border-radius: 3px;

    .dynamic-select--border & {
      border: 1px solid #636366;
      border-radius: 10px;
      padding: 16px 12px;
      &:focus {
        border-bottom-color: #636366;
      }
    }
  }

  &__caret {
    position: absolute;
    right: 20px;
    width: 12px;
    bottom: 30%;
  }

  &--border {
    select {
      border: 1px solid #636366;
      border-radius: 10px;
      padding: 16px 12px;
      &:focus {
        border-bottom-color: #636366;
      }
    }
  }

  &.invalid {
      border: 1px solid #fe0000;
    }
}
</style>
