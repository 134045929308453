<template>
  <div>
    <!-- FIXME: 這裡照理 hidden 要在 subjects assign to data 時就處理乾淨，不該留在 template 層清洗-->
    <div
      v-for="(subject, index) in subjects.filter((x) => {
        return x.hidden == undefined || !x.hidden;
      })"
      :key="`field.${index}`"
      class="field"
    >
      <!-- type: text start -->
      <template v-if="subject.type === 'text'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <input
            class="field__input"
            v-model="form[`${subject.id}`]"
            :class="{ invalid: v$.form[`${subject.id}`].$error }"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
          />
        </SubjectLayout>
      </template>
      <!-- type: text end -->

      <!-- type: textarea start -->
      <template v-if="subject.type === 'textarea'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <textarea
            class="field__input"
            v-model="form[`${subject.id}`]"
            :class="{ invalid: v$.form[`${subject.id}`].$error }"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
            v-bind:rows="subject.rows"
          ></textarea>
        </SubjectLayout>
      </template>
      <!-- type: textarea end -->

      <!-- type: select start -->
      <template v-if="subject.type === 'select'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <SharedSelect
            v-model="form[`${subject.id}`]"
            :options="subject.options"
            :hasBorder="false"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
             :class="{ invalid: v$.form[`${subject.id}`].$error }"
          />
        </SubjectLayout>
      </template>
      <!-- type: select end -->

      <!-- type: radio start -->
      <template v-if="subject.type === 'radio'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <div class="row">
            <div
              class="col-12 col-sm-6 px-2"
              v-for="(option, optionIndex) in subject.options"
              :key="index + '-' + optionIndex"
            >
              <SharedRadioButton
                v-model="form[`${subject.id}`]"
                :id="index + '-' + optionIndex"
                :value="option.value"
                :name="subject.id"
                :disabled="subject.disabled"
              >
                {{ option.text }}
              </SharedRadioButton>
            </div>
          </div>
        </SubjectLayout>
      </template>
      <!-- type: radio end -->

      <!-- type: checkbox start -->
      <template v-if="subject.type === 'checkbox'">
        <SubjectLayout
          :subject="subject"
          :validateError="v$.form[`${subject.id}`].$error"
        >
          <div class="row">
            <div
              class="col-12 col-sm-6 px-2"
              v-for="(option, optionIndex) in subject.options"
              :key="index + '-' + optionIndex"
            >
              <SharedCheckbox
                v-model="form[`${subject.id}`]"
                :id="index + '-' + optionIndex"
                :value="option.value"
                :name="subject.id"
                :disabled="subject.disabled"
              >
                {{ option.text }}
              </SharedCheckbox>
            </div>
          </div>

          <!-- <b-form-checkbox-group
            v-model="form[`${subject.id}`]"
            :stacked="subject.is_vertical"
            :disabled="subject.disabled"
            class="row"
          >
          <div class="col-12 col-sm-6 px-2" :class="form[`${subject.id}`] && form[`${subject.id}`].includes(option.value) && 'active'" v-for="(option,optionIndex) in subject.options" :key="optionIndex">
            <b-form-checkbox :value="option.value">{{ option.text }}</b-form-checkbox>
          </div>
        </b-form-checkbox-group> -->
        </SubjectLayout>
      </template>
      <!-- type: checkbox end -->

      <!-- type: collection select start -->
      <template v-if="subject.type === 'collection_select'">
        <CollectionSelect
          :subject="subject"
          v-model="form[`${subject.id}`]"
          :validateError="v$.form[`${subject.id}`].$error">
        </CollectionSelect>
      </template>
      <!-- type: collection select end -->
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SubjectLayout from "./SubjectLayout.vue";
import SharedSelect from "./Select";
import SharedRadioButton from "./Radio";
import SharedCheckbox from "./MultiCheckbox";
import CollectionSelect from "./CollectionSelect";

export default {
  components: {
    SubjectLayout,
    SharedSelect,
    SharedRadioButton,
    SharedCheckbox,
    CollectionSelect,
  },
  props: {
    inputSubjects: {
      type: Array,
      default: () => {
        return [];
      },
    },
    value: Object,
  },
  watch: {
    inputSubjects: {
      handler: function (val) {
        if (this.subjects.length > 0) return;

        for (let i = 0; i < val.length; i++) {
          let subject = val[i];

          // 把 subject.config 轉換成 subject 的屬性，這裡是專門用來處理 survey_subjects 的 DB 儲存格式用的
          if (subject.config) {
            let config = subject.config;
            delete subject.config;
            val[i] = {
              ...subject,
              ...config,
            };
          }
        }

        this.subjects = val;
      },
      immediate: true,
    },
    form: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
    value: {
      handler: function (val) {
        this.form = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      subjects: [],
      form: this.value || {},
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  validations() {
    let form = {};

    for (let i = 0; i < this.subjects.length; i++) {
      let subject = this.subjects[i];
      form[`${subject.id}`] = subject.required ? { required } : {};
    }

    return {
      form,
    };
  },
  methods: {
    displayPlaceholder(subject, defaultMessage = null) {
      if (subject.placeholder) return subject.placeholder;
      if (defaultMessage !== null) return defaultMessage;
      return "";
    },
    async validate() {
      const result = await this.v$.$validate();

      if (!result) {

        const element = document.getElementById(this.v$.$errors[0].$property);

        element.scrollIntoView({ behavior: "smooth" });

        console.log("validate invalid", result);
        return false;
      }

      return true;
    },
    
    
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";

body {
  --liff-button-color: #cccccc;
}

::v-deep .field {
  &__label {
    font-size: 16px;
    color: var(--liff-primary_text_color);
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
    color: var(--liff-primary_text_color);
  }

  &__desc {
    font-size: 14px !important;
    color: var(--liff-secondary_text_color);
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }
}

.row {
  margin: 0 -0.5rem;
}
</style>
